import axios from "axios";
import React, { useState } from "react";
import Template from "../template";
import { Button, notification } from "antd";
import copy from "../assets/icon/copy.png";

const Media = () => {
    const [data, setData] = useState([]);

    const getMedia = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_URL}/api/promo`
            );
            console.log(res);
            setData(res.data);

        } catch (error) {
            console.error("Error making POST request:", error);
        }
    }; const copyText = (text) => {
        navigator.clipboard.writeText(text);
        notification.success({
            message: "Амжилттай хууллаа.",
            duration: 3,
        });
    };


    return (
        <Template>
            <div className="o-title">Үйлчилгээний багц</div>
            <div className="background-container">

                <div className="session-button" style={{ padding: 5 }}>
                    <Button
                        className="check-button"
                        onClick={getMedia}
                    >
                        Купон код авах
                    </Button>
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: 360 }}>
                    <span className="card-time" style={{marginTop:20, marginBottom:20}}>{data.message}</span>
                    {data && data.promo_code && (
                        <div className="info">
                            <div className="code">
                                <strong onClick={() => copyText(data.promo_code)}>{data.promo_code}</strong>
                            </div>
                            <div className="copy">
                                <img
                                    src={copy}
                                    alt="ds"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        marginLeft: 20,
                                    }}
                                    onClick={() => copyText(data.promo_code)}
                                ></img>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Template>
    );
};

export default Media;
