import React, { useEffect, useState } from "react";
import webVideo from "./assets/star_desktop.mp4";
import mobileVideo from "./assets/star_mobile.mp4";
import logo from "./assets/logo.png";
import instruction from "./assets/instruction.gif";
import { useNavigate } from "react-router-dom";

const Template = ({ children }) => {
  const [videoSource, setVideoSource] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      setVideoSource(isMobile ? mobileVideo : webVideo);
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);
  const callHome = () => navigate(`/`);

  return (
    <div className="template">
      <div className="overlay"></div>
      <video
        id="video"
        autoPlay
        muted
        loop
        playsInline
        src={videoSource}
      ></video>

      {/* Header Section */}
      <div className="header-section" onClick={callHome}>
        <img src={logo} alt="logo" className="landing-logo" />
        <div className="hr-sl"></div>
        <p className="landing-text">явдаг интернэт</p>
      </div>

      {/* Body Section */}
      <div className="body-section">
        {children}
      </div>

      {/* Footer Section */}
      <div className="footer-section">
        <div className="footer-section-cont-2">
          <span style={{ color: "red", fontSize: 24, maxWidth: 360, textAlign: "center", marginBottom:20}}>Систем болон төхөөрөмжийн туршилт хийгдэж байна</span>
        </div>
        <div className="footer-section-cont-1">
          <div className="footer-first-cont">
            <a style={{ color: "white" }} href="https://form.jotform.com/243360184194052">
              Асуумж бөглөх
            </a>
          </div>
          <div className="footer-second-cont">
            <div className="img-cont2">
              <div className="link">
                <img src={instruction} style={{ height: 30, width: 30 }} alt="Logo" />
              </div>
            </div>
            <span className="c-text">
              <p className="link-text" onClick={() => window.open("/guide_b.pdf")}>
                Заавар
              </p>
            </span>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Template;
