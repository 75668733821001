import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthInit } from "./auth";

const App = () => {
  return (
    <Suspense fallback={<>aaa</>}>
        <AuthInit>
          <Outlet />
        </AuthInit>
    </Suspense>
  );
};

export default App;
