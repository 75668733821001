import React from 'react'
import { useNavigate } from 'react-router-dom'
import Template from './template'

const Landing = () => {
  const navigate = useNavigate()
  const options = [
    { id: 1, name: '1 цаг 1500₮', hour: 1, price: 1500, profile: '1 Hour' },
    { id: 2, name: '3 цаг 4000₮', hour: 3, price: 4000, profile: '3 Hour' },
      // { id: 3, name: '5 цаг 6500₮', hour: 5, price: 6500, profile: '5 Hour' },
    { id: 4, name: '7 цаг 8500₮', hour: 7, price: 8500, profile: '7 Hour' },
    { id: 5, name: '12 цаг 12000₮', hour: 12, price: 12000, profile: '12 Hour' },
    // { id: 6, name: '24 цаг 20000₮', hour: 24, price: 20000, profile: '24 Hour' }
  ]

  const handleClick = item => {
    navigate(`/order/${item}`)
  }

  const backHome = () => {
    window.open('https://sln.mn', '_blank')
  }

  return (
    <Template>
      <div className='o-title'>Үйлчилгээний багц</div>
      <div className='background-container'>
        <div className='card-container'>
          {options.map((item, index) => (
            <div
              className='card'
              key={index}
              onClick={() => handleClick(item.id)}
            >
              <span className='card-time'>{item.hour} цаг</span>
              <span className='card-price'>{item.price} ₮</span>
              <div className='text-container'>
              <span className='card-text'>дата хязгааргүй</span>
              <span className='card-text'>өндөр хурдны</span>
            </div>
            </div>
          ))}
        </div>

        {/* <span
          style={{
            color: 'white',
            fontSize: 12,
            marginTop: 10,
            marginLeft: 30
          }}
        >
          Mobile browser ашиглах бол
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ color: 'white', fontSize: 12, marginTop: 10 }}>
            https://portal.starlinknomad.mn
          </span>
          <Button
            style={{
              marginLeft: 10,
              border: '1px solid white',
              color: 'white',
              marginTop: 10,
              fontSize: 10,
              height: 20,
              display:"flex",
              justifyItems: 'center',
              alignItems:"center",
              textAlign:"center"
            }}
            type='link'
            onClick={() => {
              navigator.clipboard.writeText('https://portal.starlinknomad.mn')
            }}
          >
            хуулах
          </Button>
        </div> */}
        {/* <div className='session-button' style={{ padding: 5 }}> */}
        {/* <Button className='check-button' onClick={backHome}>
            BACK
          </Button> */}
        {/* <Button
            className="check-button"
            onClick={() => window.open("/WebStarlinkNomad.pdf")}
          >
            ЗААВАР
          </Button> */}
        {/* </div> */}
      </div>
    </Template>
  )
}

export default Landing
