import React, { useEffect, useState } from "react";
import { Button, Modal, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import copy from "../assets/icon/copy.png";
import notif from "../assets/icon/notif.png";
import ebarimt from "../assets/icon/eBarimt.png";
import Template from "../template";

const Info = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const arrayData = location.state?.data;
  const qrImage = location.state?.ebarimt;
  const ebarimt_lottery = location.state?.ebarimt_lottery;
  const amount = location.state?.amount;
  const [username] = useState(arrayData);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  useEffect(() => {
    const handlePopState = () => {
      navigate("/", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    setOpenModalInfo(true);
  }, []);

  const copyText = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      // Modern browsers
      navigator.clipboard.writeText(text).then(() => {
        notification.success({
          message: "Амжилттай хууллаа.",
          duration: 3,
        });
      }).catch((err) => {
        console.error("Clipboard copy failed:", err);
      });
    } else {
      // Fallback for iOS
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed"; // Avoid scrolling to bottom
      textArea.style.opacity = "0"; // Hide the textarea
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        notification.success({
          message: "Амжилттай хууллаа.",
          duration: 3,
        });
      } catch (err) {
        console.error("Fallback copy failed:", err);
      }
      document.body.removeChild(textArea);
    }
  };
  
  const login = () => {
    window.open("http://sln.mn", "_blank");
  };

  const handleOk = () => {
    setOpenModalInfo(false);
  };

  return (
    <Template>
      <div className="o-title" style={{marginTop:40}} >Дараах нэвтрэх кодыг хадгалж авна уу</div>
      <div className="i-container">
        <div className="ebarimt">
          <div className="info">
            <div className="code">
              <strong onClick={() => copyText(username)}>{username}</strong>
            </div>
            <div className="copy">
              <img
                src={copy}
                alt="ds"
                style={{
                  height: "100%",
                  width: "100%",
                  marginLeft: 20,
                }}
                onClick={() => copyText(username)}
              ></img>
            </div>
          </div>

          <div className="hr-sl"></div>
          <div style={{ height: 50, width:50, backgroundColor: "white", borderRadius: 12, marginTop: 10 }}>
            <img
              src={ebarimt}
              alt="QR code"
              style={{
                height: "100%",
                width: "100%",
              }}
            ></img>
          </div>
          <div className="card-title">Сугалааны дугаар: <span className="font-weight-600">{ebarimt_lottery}</span></div>
          <div className="card-title">Бүртгүүлэх дүн:<span className="font-weight-600"> {amount} ₮</span></div>
          <div className="ebarimt-img">
            <img
              src={qrImage}
              alt="QR code"
              style={{
                height: "100%",
                width: "100%",
              }}
            ></img>
          </div>

        </div>

        <Modal
          open={openModalInfo}
          onOk={handleOk}
          onCancel={handleOk}
          cancelButtonProps={{ style: { display: "none" } }}
          width={360}
          okButtonProps={{ style: { backgroundColor: "black" } }}
          closable={false}
        >
          <div style={{
            display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
          }}>
            <span style={{ fontWeight: 500, fontSize: 20, padding: 40 }}>Анхааруулга</span>
            <div style={{
              height: 70, width: 70
            }}>
              <img src={notif} style={{ height: "100%", width: "100%" }} alt="notif"></img>
            </div>
            <div style={{
              display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
            }}>
              <span style={{ textAlign: "center", marginTop: 20 }}>Төлбөр төлсөн банкны хуулганаас нэвтрэх кодоо харах боломжтой</span>
              <span style={{ textAlign: "center", marginTop: 20 }}>Нэвтрэх код болон EBarimt-н мэдээллийг имэйл-ээр илгээсэн болно.</span>
            </div>
          </div>
          <div className="footer-section-cont-2">
            <span style={{ color: "red", fontSize: 14, maxWidth: 360, textAlign: "center" ,marginTop:10}}>Систем болон төхөөрөмжийн туршилт хийгдэж байна</span>
          </div>
          {/* <div className="footer-section-cont-2">
            <a style={{ color: "black", textDecoration: "underline", textAlign: "center", marginTop:10 }} href="https://form.jotform.com/243360184194052">
              Асуумж бөглөх
            </a>
          </div> */}
        </Modal>
      </div>
      <div className="login-container">
        <Button className="check-button" onClick={login}>
          Connect
        </Button>
      </div>
    </Template>

  );
};

export default Info;
