import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Table, Spin } from "antd";
import { useLocation } from "react-router-dom";

const Users = () => {
    const location = useLocation();
    const { user } = location.state || {};
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Memoize fetchUserList to avoid recreating it on every render
    const fetchUserList = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_API_URL}/api/session`,
                { name: user.username }
            );
            if (response.data) {
                setUserList(response.data.session);
            }
        } catch (error) {
            console.error("Error fetching user list:", error);
        } finally {
            setIsLoading(false);
        }
    }, [user.username]); 

    useEffect(() => {
        fetchUserList();
    }, [fetchUserList]); 

    const columns = [
        { title: "User", dataIndex: "user", key: "user" },
        { title: "Active", dataIndex: "active", key: "active" },
        { title: "User Address", dataIndex: "user-address", key: "user-address" },
        { title: "Calling Station ID", dataIndex: "calling-station-id", key: "calling-station-id" },
        { title: "Uptime", dataIndex: "uptime", key: "uptime" },
        { title: "Upload", dataIndex: "upload", key: "upload" },
        { title: "Download", dataIndex: "download", key: "download" },
        { title: "Started", dataIndex: "started", key: "started" },
        { title: "Ended", dataIndex: "ended", key: "ended" },
        { title: "Terminate Cause", dataIndex: "terminate-cause", key: "terminate-cause" },
    ];

    return (
        <div>
            <h2>User List</h2>
            {isLoading ? (
                <div className="loading-overlay">
                    <Spin size="large" />
                </div>
            ) : (
                <Table
                    dataSource={userList}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            )}
        </div>
    );
};

export default Users;
