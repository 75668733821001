import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Input, Modal, Tabs } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import notif from '../assets/icon/notif.png'
import Template from '../template'

const options = [
  { id: 1, name: '1 цаг 1500₮', hour: 1, price: 1500, profile: '1 Hour' },
  { id: 2, name: '3 цаг 4000₮', hour: 3, price: 4000, profile: '3 Hour' },
  // { id: 3, name: '5 цаг 6500₮', hour: 5, price: 6500, profile: '5 Hour' },
  { id: 4, name: '7 цаг 8500₮', hour: 7, price: 8500, profile: '7 Hour' },
  { id: 5, name: '12 цаг 12000₮', hour: 12, price: 12000, profile: '12 Hour' }
  // { id: 6, name: '24 цаг 20000₮', hour: 24, price: 20000, profile: '24 Hour' }
]

const Order = () => {
  const { TabPane } = Tabs
  const navigate = useNavigate()
  const { id } = useParams()
  const itemId = Number(id)
  const [emailMain, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isPhoneValid, setPhoneIsValid] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalInfo, setOpenModalInfo] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const filteredItem = options.find(item => item.id === itemId)

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^\d{8}$/

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setOpenModalInfo(true)
  }, [])

  const changeEmail = e => {
    const inputEmail = e.target.value
    setEmail(inputEmail)
    setIsEmailValid(emailRegex.test(inputEmail))
  }

  const changePhone = e => {
    const inputPhone = e.target.value
    setPhone(inputPhone)
    setPhoneIsValid(phoneRegex.test(inputPhone))
  }

  const callQpay = async (price, email) => {
    try {
      // const tokenResponse = await axios.post(
      //   `${process.env.REACT_APP_BACKEND_API_URL}/api/get-token`,
      //   {}
      // )

      // const { access_token } = tokenResponse.data
      // localStorage.setItem('access_token', access_token)

      const createResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/create`,
        {
          amount: price,
          email: emailMain,
          phone: phone,
          // access_token: access_token,
          profile: filteredItem.profile
        }
      )
      const { invoice_id, qPay_shortUrl, qr_image, qr_text, urls } =
        createResponse.data.data

      localStorage.setItem('invoice_id', invoice_id)
      localStorage.setItem('qPay_shortUrl', qPay_shortUrl)
      localStorage.setItem('qr_image', qr_image)
      localStorage.setItem('qr_text', qr_text)
      localStorage.setItem('urls', urls)

      if (createResponse.status === 200) {
        const data = {
          arrayData: urls,
          qrImage: qr_image,
          invoiceId: invoice_id,
          email: emailMain,
          username: createResponse.data.username,
          phone: phone
        }
        navigate(`/user/${itemId}`, { state: data })
      }
    } catch (error) {
      console.error('Error making POST request:', error)
    }
  }

  const handleCancel = () => setOpenModal(false)

  const handleOk = () => {
    setIsChecked(true)
    setOpenModal(false)
  }

  const handleSubmitReal = async () => {
    if (!emailMain || !isEmailValid) {
      setOpenModalInfo(true)
    }
    if (emailMain && isChecked && isPhoneValid) {
      try {
        await callQpay(filteredItem.price)
      } catch (error) {
        console.error('Error in handleSubmit:', error)
      }
    }
  }

  const onChecked = () => setIsChecked(!isChecked)
  const onClicked = () => setOpenModal(true)
  const handleCancelInfo = () => setOpenModalInfo(false)

  return (
    <Template>
      <span className='choose-title'>Сонгосон багц</span>
      <div className='o-container'>
        <span className='o-package'>
          {filteredItem?.name || 'Багц олдсонгүй'}
        </span>
        <div className='o-sub-container'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Input
              className='o-input'
              onChange={changeEmail}
              value={emailMain}
              placeholder='И-мэйл оруулна уу'
            />
            <Input
              style={{ marginTop: 20 }}
              className='o-input'
              onChange={changePhone}
              value={phone}
              placeholder='Утасны дугаар оруулна уу'
            />
            {phone && !isPhoneValid && (
              <span
                style={{
                  textAlign: 'left',
                  marginTop: 20,
                  fontSize: 12,
                  color: 'white'
                }}
              >
                Утасны дугаарыг зөв оруулна уу!
              </span>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <Checkbox
              checked={isChecked}
              onChange={onChecked}
              style={{
                color: 'white',
                marginTop: 15,
                marginBottom: 30,
                textAlign: 'start'
              }}
            />
            <span
              style={{
                marginLeft: 10,
                textDecoration: 'underline',
                color: 'white',
                fontSize: 12
              }}
              onClick={onClicked}
            >
              Үйлчилгээний гэрээ / Нууцлалын бодлого
            </span>
            <span style={{ color: 'white', fontSize: 12 }}>
              {' '}
              зөвшөөрч байна.
            </span>
          </div>
          <Button className='check-button' onClick={handleSubmitReal}>
            Төлбөр төлөх
          </Button>
        </div>
      </div>

      <Modal
        open={openModalInfo}
        onOk={handleCancelInfo}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={360}
        okButtonProps={{ style: { backgroundColor: 'black' } }}
        closable={false}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <span style={{ fontWeight: 500, fontSize: 20, padding: 40 }}>
            Анхааруулга
          </span>
          <div
            style={{
              height: 70,
              width: 70
            }}
          >
            <img
              src={notif}
              style={{ height: '100%', width: '100%' }}
              alt='ico'
            ></img>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <span style={{ textAlign: 'left', marginTop: 20, fontSize: 18 }}>
              Имэйл-г зөв оруулна уу!
            </span>
            <span style={{ marginTop: 20, marginBottom: 20, fontSize: 18 }}>
              Нэвтрэх код болон EBarimt-н мэдээлэллийг илгээхэд уг и-мэйлийг
              ашиглана.
            </span>
          </div>
        </div>
      </Modal>
      <Modal
        className='modal-form'
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: 'green',
            color: '#fff'
          }
        }}
        okText='Зөвшөөрөх'
        cancelText='Татгалзах'
        cancelButtonProps={{
          style: {
            backgroundColor: '#f5222d',
            borderColor: '#f5222d',
            color: '#fff'
          }
        }}
      >
        <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
          <TabPane tab='Үйлчилгээний нөхцөл' key='1'>
            <div className='terms-p'>
              <div className='terms-title'>
                Старлинк Номад ХХК-ны WiFi үйлчилгээний гэрээ
              </div>
              <div style={{ fontSize: 12 }}>
                <ul>
                  Энэхүү гэрээ нь "Старлинк Номад" ХХК-ийн суудлын галт тэргэнд
                  санал болгож буй сансрын хиймэл дагуулын интернетэд суурилсан
                  WiFi үйлчилгээг ("Үйлчилгээ") ашиглах нөхцөл, журмыг
                  тодорхойлно. Үйлчилгээг ашигласнаар та дараах нөхцлүүдийг
                  хүлээн зөвшөөрч байна.
                  <li style={{ fontWeight: 'bold' }}>
                    1. Үйлчилгээний ерөнхий нөхцөл
                  </li>
                  <li style={{ marginLeft: 10, fontSize: 12 }}>
                    • Энэхүү үйлчилгээ нь Старлинк хиймэл дагуулын технологийг
                    ашиглан интернет холболтыг хангана.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Хөдөлгөөнт орчин, цаг агаар, газар зүйн байршлаас
                    шалтгаалан интернет тасалдах боломжтой.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Одоогийн байдлаар систем туршилтын шатанд байгаа тул
                    үйлчилгээ доголдох эрсдэлтэй бөгөөд үүнтэй холбоотой аливаа
                    хохирлыг үйлчилгээ үзүүлэгч хариуцахгүй.
                  </li>
                  <li style={{ fontWeight: 'bold' }}>
                    2. Хэрэглэгчийн эрх, үүрэг
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Үйлчилгээг зөвхөн хувийн хэрэглээнд ашиглах ба хууль бус
                    үйл ажиллагаа явуулахыг хориглоно.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Хэт их дата ашиглах, хууль бус контент дамжуулах зэрэг нь
                    сүлжээний хурдыг бууруулж, бусад хэрэглэгчдийн туршлагад
                    сөрөг нөлөө үзүүлж болзошгүй тул хязгаарлалт хийгдэж болно.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Хэрэглэгч өөрийн төхөөрөмжийн аюулгүй байдлыг хангах
                    үүрэгтэй.
                  </li>
                  <li style={{ fontWeight: 'bold' }}>
                    3. Холболт ба гүйцэтгэлийн хязгаарлалт
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Галт тэрэг хилийн бүсээс 30 км дотор байх, цаг агаарын
                    тааламжгүй нөхцөл, эсвэл байгалийн саад тотгорын улмаас
                    интернет тасалдаж болзошгүй.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Хэрэглэгчийн холболтын хурд 20 Mbps-аас ихгүй байх бөгөөд
                    энгийн хэрэглээнд (видео үзэх, цахим шуудан ашиглах гэх мэт)
                    тохиромжтой.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Худалдан авсан эрх нь 14 хоногийн хугацаанд хүчинтэй байх
                    бөгөөд энэ хугацаанд олон дахин нэвтрэх боломжтой.
                  </li>
                  <li style={{ fontWeight: 'bold' }}>4. Төлбөрийн нөхцөл</li>
                  <li style={{ marginLeft: 10 }}>
                    • Үйлчилгээг онлайнаар банкны апп ашиглан худалдан авна.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Худалдан авалт амжилттай болсны дараа нэвтрэх нууц үг
                    болон e-barimt код таны имэйл хаягт илгээгдэнэ.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Худалдан авсан эрхийг 24 цагийн дотор ашиглах
                    шаардлагатай.
                  </li>
                  <li style={{ fontWeight: 'bold' }}>
                    {' '}
                    5. Хариуцлагын хязгаарлалт
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Үйлчилгээний тасалдал, доголдол болон дараах хүчин зүйлээс
                    үүдсэн асуудалд "Старлинк Номад" ХХК хариуцлага хүлээхгүй:
                  </li>
                  <li style={{ marginLeft: 20 }}>
                    o Сүлжээний хамрах хүрээнээс гарсан.
                  </li>
                  <li style={{ marginLeft: 20 }}>o Хилийн бүсэд орсон.</li>
                  <li style={{ marginLeft: 20 }}>
                    o Цаг агаарын таагүй нөхцөл (бороо, шуурга, цас).
                  </li>
                  <li style={{ marginLeft: 20 }}>
                    o Галт тэрэгний техник гэмтэл.
                  </li>
                  <li style={{ marginLeft: 20 }}>
                    o Хиймэл дагуулын сүлжээний доголдол.
                  </li>
                  <li style={{ marginLeft: 20 }}>
                    o Хэрэглэгчийн төхөөрөмжийн тохиргооны асуудал.
                  </li>
                  <li style={{ fontWeight: 'bold' }}>
                    6. Нууцлал ба мэдээллийн хамгаалалт
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Үйлчилгээг сайжруулах зорилгоор хэрэглэгчийн холболтын
                    мэдээллийг цуглуулж болно.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Бид хэрэглэгчийн хувийн мэдээллийг гуравдагч этгээдэд
                    зарах, дамжуулахгүй.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Илүү дэлгэрэнгүй мэдээллийг Нууцлалын бодлогоос үзнэ үү.
                  </li>
                  <li style={{ fontWeight: 'bold' }}>
                    7. Гэрээний өөрчлөлт ба хүчин төгөлдөр байдал
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • "Старлинк Номад" ХХК нь энэхүү гэрээг шаардлагатай үед
                    шинэчлэх эрхтэй.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Өөрчлөлт хийгдсэн тохиолдолд хэрэглэгчид мэдээлэл өгч,
                    шинэчилсэн нөхцлийг дагаж мөрдөнө.
                  </li>
                  <li style={{ fontWeight: 'bold' }}> 8. Холбоо барих</li>
                  <li style={{ marginLeft: 10 }}>
                    • Үйлчилгээтэй холбоотой асуулт, гомдол гаргах бол
                    demo@starlinknomad.mn хаягаар холбогдоно уу.
                  </li>
                </ul>
                Энэхүү үйлчилгээний нөхцлийг зөвшөөрч, үйлчилгээг ашиглахыг
                баталгаажуулж байна.
              </div>
            </div>
          </TabPane>

          <TabPane tab='Нууцлалын бодлого' key='2'>
            <div className='terms-p'>
              <div className='terms-title'>
                Старлинк Номад WiFi Үйлчилгээний Нууцлалын Бодлого
              </div>
              <div style={{ fontSize: 12 }}>
                <ul>
                  <li>
                    1. Танилцуулга Энэхүү Нууцлалын Бодлого нь Старлинк Номад
                    ХХК (“Бид”) -ны үзүүлж буй WiFi үйлчилгээг (“Үйлчилгээ”)
                    ашиглах үед хэрэглэгчийн хувийн мэдээлэл хэрхэн цуглуулж,
                    ашиглагдаж, хамгаалагдах талаар мэдээлэл өгнө. Энэхүү
                    Үйлчилгээг ашигласнаар та энэхүү Нууцлалын Бодлогыг зөвшөөрч
                    буйгаа илэрхийлнэ.
                  </li>

                  <li>
                    2. Бидний цуглуулдаг мэдээлэл Бид дараах төрлийн мэдээллийг
                    цуглуулж болно:
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Хувийн мэдээлэл: Үйлчилгээнд бүртгүүлэх болон төлбөр төлөх
                    үед таны нэр, имэйл хаяг, утасны дугаар зэрэг мэдээлэл.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Ашиглалтын мэдээлэл: WiFi-д холбогдсон төхөөрөмжийн
                    мэдээлэл, IP хаяг, интернэт хэрэглээний хугацаа, сүлжээний
                    гүйцэтгэлийн мэдээлэл зэрэг.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Күүки болон хяналтын технологи: Хэрэглэгчийн туршлагыг
                    сайжруулах зорилгоор вэб хөтчийн күүки болон бусад хяналтын
                    технологийг ашиглаж болно.
                  </li>
                  <li>
                    3. Мэдээллийг хэрхэн ашиглах вэ? Бид таны мэдээллийг дараах
                    зорилгоор ашиглана:
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Үйлчилгээг үзүүлэх: WiFi холболтыг хангах, хэрэглэгчдэд
                    дэмжлэг үзүүлэх.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Сайжруулалт, судалгаа: Үйлчилгээний гүйцэтгэлийг
                    сайжруулах, хэрэглэгчийн зан төлөвийг судлах.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Харилцаа холбоо: Таны бүртгэлтэй имэйл эсвэл утасны
                    дугаараар дамжуулан шинэчлэл, урамшууллын талаар мэдээлэх.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Аюулгүй байдал: Луйвар болон хууль бус үйлдлээс сэргийлэх.
                  </li>
                  <li>
                    4. Мэдээлэл хуваалцах Бид таны хувийн мэдээллийг дараах
                    тохиолдолд гуравдагч этгээдтэй хуваалгаж болно:
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Үйлчилгээг сайжруулах, техникийн дэмжлэг үзүүлэх зорилгоор
                    үйлчилгээний түнш байгууллагуудтай.
                  </li>
                  <li style={{ marginLeft: 10 }}>
                    • Хууль эрх зүйн шаардлагын дагуу төрийн байгууллагад
                    мэдээлэл өгөх шаардлага гарсан тохиолдолд.
                  </li>

                  <li>
                    {' '}
                    5. Мэдээллийн аюулгүй байдал Бид таны мэдээллийг алдагдахаас
                    хамгаалах зорилгоор зохих арга хэмжээнүүдийг авдаг. Гэвч
                    нээлттэй сүлжээ ашиглаж буйгаас шалтгаалан мэдээлэл бүрэн
                    хамгаалагдана гэсэн баталгаа өгөх боломжгүй.
                  </li>
                  <li>
                    6. Нууцлалын бодлогын өөрчлөлтүүд Бид энэ бодлогыг шинэчлэх
                    эрхтэй. Өөрчлөлт оруулах тохиолдолд манай вэбсайт болон
                    үйлчилгээний сувгуудаар дамжуулан мэдэгдэх болно.{' '}
                  </li>
                  <li>
                    7. Холбоо барих Нууцлалын бодлоготой холбоотой аливаа
                    асуулт, санал хүсэлтийг дараах хаягаар хүлээн авна: Имэйл:
                    demo@starlinknomad.mn
                  </li>
                </ul>
                Энэхүү Нууцлалын Бодлого нь Старлинк Номад WiFi үйлчилгээг
                ашиглах бүх хэрэглэгчдэд хамаарна.
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    </Template>
  )
}

export default Order
