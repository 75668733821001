import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Spin } from "antd";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/user`
      );
      if (response.data) {
        setUserList(response.data);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Invoice ID",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
  ];

  const onRowClick = (record) => {
    navigate(`/user-info`, { state: { user: record } });
  };

  return (
    <div>
      <h2>User List</h2>
      {isLoading ? (
        <div className="loading-overlay">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={userList}
          columns={columns}
          rowKey="id"
          pagination={{ pageSize: 10 }}
          onRow={(record) => ({
            onClick: () => onRowClick(record), 
          })}
        />
      )}
    </div>
  );
};

export default Users;
