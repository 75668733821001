import { Routes, Route, BrowserRouter } from "react-router-dom";
import App from "./App";
import Home from "./Home";
import Order from "./components/Order";
import User from "./components/User";
import Info from "./components/Info";
import Status from "./components/Status";
import Users from "./components/admin/User";
import UserInfo from "./components/admin/UserInfo";
import Media from "./components/SoMedia";
import CheckUser from "./components/CheckUser";

const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/order/:id" element={<Order />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/info" element={<Info />} />
          <Route path="/status" element={<Status />} />
          <Route path="/user-list" element={<Users />} />
          <Route path="/user-info" element={<UserInfo />} />
          <Route path="/media" element={<Media />} />
          <Route path="/check" element={<CheckUser />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
