import React, { useState } from 'react'
import { Button, Input, notification } from 'antd'
import Template from '../template'
import axios from 'axios'

const CheckUser = () => {
  const [phone, setPhone] = useState('')
  const [userList, setUserList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const check = async () => {
    if (!phone) {
      notification.error({ message: 'Please enter an phone!' })
      return
    } else {
      try {
        const resPay = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/check-user`,
          { params: { phone } }
        )

        if (resPay.data) {
          setUserList(resPay.data)
        } else {
          setIsLoading(true)
        }
      } catch (error) {
        console.error('Error checking payment status:', error)
      }
    }
  }

  return (
    <Template>
      <div className='o-title' style={{ marginTop: 40 }}>
        Хэрэглэгч шалгах
      </div>
      <div className='i-container'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <span style={{ color: 'white', marginBottom: 10 }}>
            Утасны дугаар
          </span>
          <Input
            value={phone}
            onChange={e => setPhone(e.target.value)}
            placeholder='Утасны дугаар'
          />
        </div>
      </div>
      <div className='login-container'>
        <Button className='check-button' onClick={check} loading={isLoading}>
          Шалгах
        </Button>
      </div>
      <div>
        {userList.length > 0 ? (
          userList.map((item, index) => (
            <div key={index} style={{ color: 'white', marginTop: 10 , display:"flex", flexDirection:"row"}}>
              <span>Хэрэглэгчийн нэр: </span>
              <span>{item.username}</span>
            </div>
          ))
        ) : (
          <div style={{ color: 'white', marginTop: 10 }}>Эрх үүсээгүй байна</div>
        )}
      </div>
    </Template>
  )
}

export default CheckUser
